<template>
  <section>
    <breadcrumb :title="$t('accounting')" :subtitle="$t('real_balances')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-5 is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts.data"
          ref="table"
          :paginated="false"
          :per-page="itemsPerPage"
      >

        <b-table-column field="data" :label="$t('agency')" v-slot="props">
            <span class="col name">
              {{ props.row.agency.name }}
            </span>
        </b-table-column>
        <b-table-column field="code" :label="$t('debit_mlc')" sortable v-slot="props">
           <span class="col usd">
             {{ props.row.debitMLC }}
           </span>
        </b-table-column>
        <b-table-column field="code" :label="$t('pay_mlc')" sortable v-slot="props">
           <span class="col usd">
             {{ props.row.paidOutMLC }}
           </span>
        </b-table-column>
        <b-table-column field="code" :label="$t('pending_mlc')" sortable v-slot="props">
           <span class="col usd">
             {{ props.row.pendingMLC }}
           </span>
        </b-table-column>
        <b-table-column field="code" :label="$t('debit_usd')" sortable v-slot="props">
           <span class="col usd">
             {{ props.row.debitUSD }}
           </span>
        </b-table-column>
        <b-table-column field="code" :label="$t('pay_usd')" sortable v-slot="props">
           <span class="col usd">
             {{ props.row.paidOutUSD }}
           </span>
        </b-table-column>
        <b-table-column field="code" :label="$t('pending_usd')" sortable v-slot="props">
           <span class="col usd">
             {{ props.row.pendingUSD }}
           </span>
        </b-table-column>

        <b-table-column field="name" :label="$t('debit_real_cup')" v-slot="props">
          <span class="col cup">
         {{ props.row.realDebitCUP }}
          </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('debit_cup')" v-slot="props">
          <span class="col usd">
         {{ props.row.debitCUP }}
          </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('pay_cup')" v-slot="props">
          <span class="col usd">
         {{ props.row.paidOutCUP }}
          </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('pending_cup')" v-slot="props">
          <span class="col usd">
         {{ props.row.pendingCUP }}
          </span>
        </b-table-column>
        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div class="mx-10" @click="setModalsAdd(props.row.agency)">
              <b-tooltip type="is-primary" :label="$t('balance_plus')">
                <b-icon type="is-primary" icon="database-plus"
                        class="mx-4 cursor-pointer"></b-icon>
              </b-tooltip>
            </div>
            <div class="mx-10">
              <b-tooltip type="is-danger" :label="$t('Logs')">
                <b-icon type="is-danger" icon="eye"
                        class="mx-4 cursor-pointer"></b-icon>
              </b-tooltip>
            </div>
          </div>
        </b-table-column>

      </b-table>
      <paginate v-if="desserts.data.length"
          :count="desserts.totalRecords"
          :per_page="desserts.itemsPerPage"
          :current-page="desserts.currentPage"
          :total-pages="desserts.totalPages"
          @first="getLast(true)"
          @previous="getAgenciesDebits(desserts.currentPage - 1,desserts.itemsPerPage)"
          @next="getAgenciesDebits(desserts.currentPage + 1,desserts.itemsPerPage)"
          @last="getLast"
          @perPage="getAgenciesDebits(desserts.currentPage,$event)"
          @search="getAgenciesDebits($event,desserts.itemsPerPage)"

      />
    </section>
    <b-modal
        v-model="isModalAgencyBalanceActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modal-add-real-balance @reload="getAgenciesDebits(desserts.currentPage, desserts.itemsPerPage)" :object="agency"
                                @close="props.close"></modal-add-real-balance>
      </template>
    </b-modal>
  </section>

</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions, mapGetters} from "vuex";
import {USER_TYPE} from "@/enum/userType";
import ModalAddRealBalance from "@/components/modals/modalAddRealBalance";

export default {
  name: "HistoryComponent",
  components: {ModalAddRealBalance, Paginate, Breadcrumb},
  data() {
    return {
      userTypes: USER_TYPE,
      isModalAgencyBalanceActive: false,
      propsModals: {},
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      desserts: null,
      agenciesList: [],
      idQuery: null,
      agency: null

    }
  },
  methods: {
    ...mapActions({
      fetchAgencyDebits: 'FETCH_AGENCY_DEBITS',

    }),
    setModalsAdd(props) {
      this.agency = props;
      this.isModalAgencyBalanceActive = !this.isModalAgencyBalanceActive
    },
    selectName({name}) {
      return name
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getAgenciesDebits(page, this.desserts.itemsPerPage)
    },
    getAgenciesDebits(current = 1, record = 10, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchAgencyDebits(data).then(value => {
          resolve(value)
          this.desserts = value
        })
      })
    },
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    reload() {
      window.location.reload()
    }
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER',
    })
  },
  created() {
    this.getAgenciesDebits()
        .then(value => {
          this.desserts = value
        })
  },
}
</script>

<style scoped lang="scss">
.hr-divider {
  height: 1px;
  background: #AEAEAE;
}

.col {
  font-weight: 900;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.cup {
    font-size: 16px;
    color: #666BAA;
  }

  &.minus {
    color: #E00F0F !important;
  }

  &.usd {
    color: #0B2B46;
    font-size: 16px;
  }

}

.total-balance {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}
</style>